import {
  Card,
  Title1,
  Body2,
  CardHeader,
  Subtitle2,
} from "@fluentui/react-components";

interface CardProps {
  title: string;
  body: string;
  author: string;
}

export default function CustomCard({ title, body, author }: CardProps) {
  console.log("title", title);
  console.log("body", body);
  return (
    <div
      className="Card"
      style={{
        width: "100%",
        minWidth: "300px",
      }}
    >
      <Card>
        <CardHeader
          header={
            <div className="Test" style={{ width: "100%" }}>
              <Title1>
                <b>{title}</b>
              </Title1>
              <br></br>
              <Body2 align="center">{body}</Body2>
              <br></br>
              <Subtitle2 align="center">- {author}</Subtitle2>
            </div>
          }
        />
      </Card>
    </div>
  );
}
