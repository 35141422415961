import { useState } from "react";
import {
  Text,
  Textarea,
  Input,
  Button,
  Field,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@fluentui/react-components";

export default function PopoverContent() {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [author, setAuthor] = useState("");

  function handleClick() {
    console.log("clicked");

    const dateadded = new Date().toUTCString();

    fetch("https://services.quietwildflower.com/new_message", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ title, body, author, dateadded }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        window.location.reload(); // Reload the page
      })
      .catch((error) => console.error(error));
  }

  return (
    <DialogContent>
      <div className="field">
        <Field label="Message Title">
          <Input
            className="Input"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ width: "100%" }} // This is a hack to make the input field fill the width of the dialog
          />
        </Field>
      </div>
      <div className="field">
        <Field required label="Message Body">
          <Textarea
            className="Textarea"
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />
        </Field>
      </div>
      <div className="field">
        <Field label="Your Name">
          <Input
            className="Input"
            value={author}
            onChange={(e) => {
              setAuthor(e.target.value);
            }}
            style={{ width: "100%" }} // This is a hack to make the input field fill the width of the dialog
          />
        </Field>
      </div>
      <DialogActions>
        <Button onClick={handleClick} style={{ marginTop: "10px" }}>
          Submit
        </Button>
      </DialogActions>
    </DialogContent>
  );
}
