import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Title1,
  Body2,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
} from "@fluentui/react-components";

import CustomCard from "./Card";
import PopoverContent from "./PopoverContent";

interface Message {
  id: number;
  title: string;
  body: string;
  author: string;
}

export default function Memories() {
  //   const PopoverContent = () => {
  //     return (
  //       <div>
  //         <h3>Popover content</h3>
  //         <div>This is some popover content</div>
  //       </div>
  //     );
  //   };
  const navigate = useNavigate();
  const [messages, setMessages] = useState<Message[]>([]);

  const loadMessages = async () => {
    try {
      const response = await fetch(
        "https://services.quietwildflower.com/messages"
      );
      const messages = await response.json();
      setMessages(messages);
    } catch (error) {
      console.error("Failed to fetch messages:", error);
      alert("Failed to fetch messages. Please try again later.");
    }
  };

  useEffect(() => {
    loadMessages();
  }, []);

  return (
    <div className="App">
      <nav className="Navigation">
        <Button onClick={() => navigate("/")} className="home">
          Home
        </Button>
      </nav>
      <div className="Cards">
        <Dialog modalType="non-modal">
          <DialogTrigger>
            <Button style={{ marginTop: "5px" }} className="PopoverTrigger">
              Leave a Message
            </Button>
          </DialogTrigger>
          <DialogSurface>
            <DialogBody>
              <DialogTitle>Leave a Message</DialogTitle>
              <PopoverContent />
            </DialogBody>
          </DialogSurface>
        </Dialog>
        {messages.map((message) => (
          <CustomCard
            key={message.id}
            title={message.title}
            body={message.body}
            author={message.author}
          />
        ))}
      </div>
    </div>
  );
}
