import "./App.css";
import Home from "./Home";
import About from "./About";
import Memories from "./Memories";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { FluentProvider, webLightTheme } from "@fluentui/react-components";

export default function App() {
  return (
    <FluentProvider theme={webLightTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/memories" element={<Memories />} />
        </Routes>
      </BrowserRouter>
    </FluentProvider>
  );
}
