import { Button, Title1, Body2 } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
export default function About() {
  const navigate = useNavigate();
  return (
    <div className="App">
      <nav className="Navigation">
        <Button onClick={() => navigate("/")} className="home">
          Home
        </Button>
      </nav>
      <header className="App-header">
        <Title1>About</Title1>
        <Body2>This is the about page.</Body2>
      </header>
    </div>
  );
}
