import logo from "./QuietLogo.jpg";
import { useNavigate } from "react-router";
import { Image, Link, Title1, Button } from "@fluentui/react-components";

export default function Home() {
  const navigate = useNavigate();
  return (
    <div className="App">
      <header className="App-header">
        <Image src={logo} className="App-logo" alt="logo" />
        <Title1 align="center">This Memorial Site is Under Construction</Title1>
        <Link href="mailto: admin@quietwildflower.com">
          admin@quietwildflower.com
        </Link>
        <div className="Navigation-Buttons">
          <Button onClick={() => navigate("/about")} className="Navbutton">
            About
          </Button>
          <Button onClick={() => navigate("/memories")} className="Navbutton">
            Memories
          </Button>
        </div>
      </header>
    </div>
  );
}
